"use client";

import { Metadata } from "next";
import { signIn, useSession } from "next-auth/react";
import { redirect } from "next/navigation";
import { useState } from "react";
import { useForm } from "react-hook-form";

export default function Login() {
  const { data: session, status } = useSession();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [mailRequested, setMailRequested] = useState<string | null>(null);

  const onSubmit = async (formData: any) => {
    setMailRequested(formData.email);
    signIn("email", {
      email: formData.email,
      callbackUrl: "/",
      redirect: false,
    });
  };

  if (status !== "loading" && session) redirect("/");
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-6 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md"></div>

      <div className="mt-2 sm:mt-4 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <div>
            <div className="grid grid-cols-1 gap-4">
              <div
                onClick={() =>
                  signIn("google", { callbackUrl: "https://vendermonedas.es/" })
                }
              >
                <a className="w-full cursor-pointer inline-flex justify-evenly justify-center py-6 px-6 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                  <span className="sr-only">Accede con Google en 1 click</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    width="60px"
                    height="60px"
                  >
                    <path
                      fill="#fbc02d"
                      d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12	s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                    />
                    <path
                      fill="#e53935"
                      d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039	l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                    />
                    <path
                      fill="#4caf50"
                      d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                    />
                    <path
                      fill="#1565c0"
                      d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                    />
                  </svg>
                  <div className="flex ml-3 justify-center text-center items-center text-xl font-semibold text-gray-900">
                    Acceder con Google <br /> en 1 click
                  </div>
                </a>
              </div>
            </div>
            <div className="relative mt-8">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-200" />
              </div>
              <div className="relative flex justify-center text-sm font-medium leading-6">
                <span className="bg-white px-6 text-gray-900">
                  o con tu email
                </span>
              </div>
            </div>
            {!mailRequested ? (
              <form
                className="space-y-6 mt-6"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Correo electrónico
                  </label>
                  <div className="mt-2">
                    <input
                      {...register("email", {
                        required: true,
                      })}
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="pl-4 block w-full rounded-md border-gray-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Envíame un enlace mágico
                  </button>
                </div>
              </form>
            ) : (
              <p className="mt-6 mx-4 text-sm text-center text-gray-700 justify-center">
                ¡Acabamos de enviarte un correo a {mailRequested}! <br /> haz
                click en el enlace del correo para acceder a tu cuenta
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
